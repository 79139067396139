<template>
  <div class="user-chat">
    <!-- 导航栏 -->
    <van-nav-bar
      class="app-nav-bar"
      title="小智同学"
      left-arrow
      @click-left="$router.back()"
    />

    <!-- 消息列表 -->
    <van-cell-group class="message-list" ref="message-list">
      <van-cell 
        :title="item.mag"
        v-for="(item, index) in messages"
        :key="index"
      />
    </van-cell-group>

    <!-- 发送消息 -->
    <van-cell-group class="send-message-wrap">
      <van-field 
        v-model="message" 
        placeholder="请输入消息"
        :border="false" 
      />
      <van-button 
        type="primary" 
        size="small" 
        class="btn"
        @click="onSend"
      >
        发送
      </van-button>
    </van-cell-group>
  </div>
</template>

<script>
import io from 'socket.io-client'
import { getItem, setItem } from '@/utils/storage'

export default {
  name: 'UserChat',
  components: {},
  props: {},
  data() {
    return {
      message: '',
      socket: null, // webSocket 通信对象
      messages: getItem('chat-messages') || [] // 消息列表
    };
  },
  computed: {},
  watch: {
    messages() {
      setItem('chat-messages', this.messages)
      // 如果你要操作数据之后立即操作数据影响的视图DOM，那么最好把代码放到 nextTick 函数中
      // 数据改变影响视图更新这件事不是立即的
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    }
  },
  created() {
    // 建立链接
    const socket = io('http://ttapi.research.itcast.cn')
    this.socket = socket

    socket.on('connect', () => {
      console.log('通信建立成功');
    })

    // 断开连接了
    socket.on('disconnect', () => {
      console.log('断开连接了');
    })

    // 监听 message 事件，接收服务端消息
    socket.on('message', data => {
      this.messages.push(data)
    })
  },
  mounted() {
    this.scrollToBottom()
  },
  methods: {
    onSend() {
      // 请求发送消息
      const data = {
        msg: this.message, 
        timestamp: Date.now()
      }
      this.socket.emit('message', data)

      // 将用户发出去的消息存储到数组中
      this.messages.push(data)

      // 清空输入框
      this.message = ''
    },

    scrollToBottom() {
      const list = this.$refs['message-list']
      list.scrollTop = list.scrollHeight
    }
  }
};
</script>
<style lang="less" scoped>
.message-list {
  position: fixed;
  left: 0;
  right: 0;
  top: 46px;
  bottom: 44px;
  overflow-y: auto;
}
.send-message-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 14px;
  align-items: center;
  .btn {
    width: 80px;
  }
}
</style>